// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"68969c1b4424a867bf006e56fb9e2a1697046bee"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN ?? 'https://10373cea1e5ec398c02881b5ea773a7b@s28014.creoline.cloud/7',
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT ?? 'local',
  tracesSampleRate: 1,
  debug: false,
  integrations: [
    Sentry.captureConsoleIntegration({ levels: ['error', 'debug', 'assert','log','info'] }),
  ],
});
